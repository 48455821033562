/* eslint-disable */
import PermissionChecker from '@/security/permission-checker';

export class SliderPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.create = permissionChecker.match('createSlider');
    this.read = permissionChecker.match('viewSliders');
    this.edit = permissionChecker.match('editSlider');
    this.destroy = permissionChecker.match('deleteSlider');
  }
}
